.active {
  text-decoration: underline !important;
  text-decoration: thickness !important;
}

.pointer {
  cursor: pointer;
}

.card-button {
  border-radius: 0px;
}

.hoverable:hover {
  border-right: 5px solid #0d63fd;
  border-bottom: 5px solid #0d63fd;
  border-radius: 12px;
}

.ant-checkbox-wrapper:first-of-type{
  margin-left: 8px; 
}

.form-control {
  -webkit-box-shadow: none !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}

/* jumbotron color animation css */
.jumbotron {
  width: 30wh;
  height: 30vh;
  color: #fff;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}
 
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
 
@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
 
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
